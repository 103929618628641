import classNames from "classnames";
import {
  ArrowDown,
  ArrowUp,
  Copy,
  CreditCard,
  FolderClock,
  Landmark,
  QrCode,
} from "lucide-react";

import { copyContent, hiddenMiddleCharacter } from "utils";

import { useAuth } from "components/containers/auth";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INVEST_ROUTES } from "router/invest-routes";
import { formatNumberToAmountKeepEnableDecimal } from "utility/number-utils";
import bnb from "../assets/bnb.svg";
import ego from "../assets/eGO.png";
import eusd from "../assets/eUSD.png";
import usdt from "../assets/usdt.svg";
import vrc from "../assets/vrc.svg";
import CryptoList from "../component/crypto-list";
import ReceiveScreen from "../component/receive";
import SendScreen from "../component/send";
import { withCryptoWrapper } from "../component/wrapper";
import TooltipSystem from "components/e-control/tooltip-system";
import { useSelector } from "react-redux";

export const dataCoin = [
  {
    icon: usdt,
    name: "USDT",
    des: "BNB Smart Chain",
    price: "$0.99",
    quantity: "0,7505",
    balance: "$0.75",
    rate: "-0.1%",
    decrease: false,
  },

  {
    icon: bnb,
    name: "BNB",
    des: "BNB Beacon Chain",
    price: "$63.783,25",
    quantity: "0",
    balance: "$0",
    rate: "+0.17%",
    decrease: true,
  },

  {
    icon: vrc,
    name: "eVND",
    des: "eVND Smart Chain",
    price: "$594.14",
    quantity: "0",
    balance: "$0",
    rate: "-0.19%",
    decrease: false,
  },

  {
    icon: eusd,
    name: "eUSD",
    des: "Polygon ",
    price: "$1.109",
    quantity: "0",
    balance: "$0",
    rate: "0.10%",
    decrease: true,
  },

  {
    icon: ego,
    name: "eGO",
    des: "Solana ",
    price: "$2.109,19",
    quantity: "0",
    balance: "$0",
    rate: "0.10%",
    decrease: false,
  },
];

const TabEnum = {
  Crypto: 1,
  DApps: 2,
};

const HomeWalletWeb3Page = () => {
  const navigate = useNavigate();

  const { user } = useAuth();
  const { web3Wallet } = user;

  const [tabIndex, setTabIndex] = useState(TabEnum.Crypto);

  const [isSendPopupOpen, setIsSendPopupOpen] = useState(false);
  const [isReceivePopupOpen, setIsReceivePopupOpen] = useState(false);

  const { cryptos } = useSelector((state) => state.web3);

  const totalBalance = useMemo(() => {
    return cryptos.reduce(
      (acc, curr) => acc + curr?.myBalance * curr?.price,
      0
    );
  }, [cryptos]);

  const dataMenu = [
    {
      icon: <CreditCard size={20} />,
      text: "Buy",
      link: "",
    },

    {
      icon: <Landmark size={20} />,
      text: "Sell",
      link: "",
    },

    {
      icon: <FolderClock size={20} />,
      text: "History",
      link: INVEST_ROUTES.WALLET_WEB3_HISTORY,
    },
  ];

  return (
    <>
      <div className="max-w-[700px] h-full m-auto p-4">
        <div className="flex items-start justify-between gap-x-2">
          <div className="flex flex-col gap-1">
            <span className="text-base font-medium">
              <TooltipSystem title={web3Wallet?.address}>
                {hiddenMiddleCharacter(web3Wallet?.address ?? "")}
              </TooltipSystem>
            </span>
            <p className="text-3xl text-[#000] font-extrabold">
              {formatNumberToAmountKeepEnableDecimal(totalBalance, 2)}
            </p>
          </div>

          <div className="flex items-center gap-x-2">
            <div
              className="p-2 bg-slate-200 cursor-pointer hover:opacity-80"
              onClick={() => copyContent(web3Wallet?.address)}
            >
              <Copy size={12} />
            </div>

            <div className="p-2 bg-slate-200 cursor-pointer hover:opacity-80">
              <QrCode size={12} />
            </div>
          </div>
        </div>

        <div className="mt-8 flex items-center justify-between gap-x-2">
          <div
            className="flex flex-col items-center justify-center cursor-pointer gap-y-2"
            onClick={() => {
              setIsSendPopupOpen(true);
            }}
          >
            <div className="p-4 bg-slate-200 rounded-full hover:opacity-80">
              <ArrowUp size={20} />
            </div>

            <span className="text-base text-[#000] font-semibold">Send</span>
          </div>

          <div
            className="flex flex-col items-center justify-center cursor-pointer gap-y-2"
            onClick={() => {
              setIsReceivePopupOpen(true);
            }}
          >
            <div className="p-4 bg-slate-200 rounded-full hover:opacity-80">
              <ArrowDown size={20} />
            </div>

            <span className="text-base text-[#000] font-semibold">Receive</span>
          </div>

          {dataMenu.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center cursor-pointer gap-y-2"
              onClick={() => navigate(item?.link)}
            >
              <div className="p-4 bg-slate-200 rounded-full hover:opacity-80">
                {item.icon}
              </div>

              <span className="text-base text-[#000] font-semibold">
                {item.text}
              </span>
            </div>
          ))}
        </div>

        <div className="flex items-center justify-between px-16 mt-10">
          <div
            className={classNames(
              "text-lg font-bold transition-all cursor-pointer relative",
              tabIndex === TabEnum.Crypto && "text-primary"
            )}
            onClick={() => setTabIndex(TabEnum.Crypto)}
          >
            Crypto
            <div
              className={classNames(
                "absolute bottom-0 left-1/2 -translate-x-1/2 w-0 transition-all h-[3px] bg-primary",
                tabIndex === TabEnum.Crypto && "w-1/2"
              )}
            />
          </div>

          <span
            className={classNames(
              "text-lg font-bold transition-all cursor-pointer relative",
              tabIndex === TabEnum.DApps && "text-primary"
            )}
            onClick={() => setTabIndex(TabEnum.DApps)}
          >
            dApps
            <div
              className={classNames(
                "absolute bottom-0 left-1/2 -translate-x-1/2 w-0 transition-all h-[3px] bg-primary",
                tabIndex === TabEnum.DApps && "w-1/2"
              )}
            />
          </span>
        </div>

        {tabIndex === TabEnum.Crypto && <CryptoList />}
      </div>

      {isSendPopupOpen && (
        <SendScreen
          isOpen={isSendPopupOpen}
          onClose={() => {
            setIsSendPopupOpen(false);
          }}
        />
      )}

      {isReceivePopupOpen && (
        <ReceiveScreen
          isOpen={isReceivePopupOpen}
          onClose={() => {
            setIsReceivePopupOpen(false);
          }}
        />
      )}
    </>
  );
};

export default withCryptoWrapper(HomeWalletWeb3Page);

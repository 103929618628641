import ButtonSystem from "components/e-control/button-system";
import InputNumberSystem from "components/e-control/forms/form-elements/input-number-system/input-number-system";
import InputSystem from "components/e-control/forms/form-elements/input-system";
import FormGroupSystem from "components/e-control/forms/form-group";
import { X } from "lucide-react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { Form } from "reactstrap";
import { formatNumberToAmountKeepEnableDecimal } from "utility/number-utils";

const SendForm = ({ onSubmit }) => {
  const form = useForm({
    mode: "onChange",
  });

  const { selectedCoin } = useSelector((state) => state.web3);

  const {
    control,
    formState: { errors },
  } = form;

  const amount = useWatch({ name: "amount", control });

  const handlePaste = async () => {
    const text = await navigator.clipboard.readText();
    if (text) {
      form.setValue("address", text);
    }
  };

  return (
    <Form control={control} className="h-full">
      <div className="space-y-8 flex-[2] pb-24">
        <FormGroupSystem
          label="Address or Domain Name"
          name="address"
          form={form}
          rules={{ required: true }}
          render={({ field }) => (
            <div className="relative">
              <InputSystem className="h-12 line-clamp-1 pr-24" {...field} />

              <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center gap-3">
                {!!field.value && (
                  <span
                    className=" text-sm rounded-full bg-gray-400 flex items-center justify-center p-1 text-white"
                    onClick={() => form.setValue("address", "")}
                  >
                    <X className="size-3 cursor-pointer" />
                  </span>
                )}

                <span
                  className="text-primary text-sm cursor-pointer"
                  onClick={handlePaste}
                >
                  Dán
                </span>
              </div>
            </div>
          )}
        />

        <FormGroupSystem
          label="Amount"
          name="amount"
          form={form}
          rules={{ required: true }}
          render={({ field }) => (
            <>
              <div className="relative">
                <InputNumberSystem
                  className="[&_input]:!h-12 [&_.rc-input-number-input-wrap]:!w-full [&_input]:!w-full [&_input]:!bg-transparent line-clamp-1 [&_input]:pr-24 "
                  {...field}
                />

                <div className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center gap-3">
                  {!!field.value && (
                    <span
                      className=" text-sm rounded-full bg-gray-400 flex items-center justify-center p-1 text-white"
                      onClick={() => form.setValue("amount", 0)}
                    >
                      <X className="size-3 cursor-pointer" />
                    </span>
                  )}

                  <span
                    className="text-primary text-sm cursor-pointer"
                    onClick={() => form.setValue("amount", "")}
                  >
                    Tối đa
                  </span>
                </div>
              </div>
              <small>{`= ${formatNumberToAmountKeepEnableDecimal(
                amount * selectedCoin?.price,
                2
              )}`}</small>
            </>
          )}
        />
      </div>
      <div className="fixed bottom-6 left-0 right-0 px-4">
        <ButtonSystem
          className="w-full !h-12"
          color="primary"
          onClick={form.handleSubmit((formData) => onSubmit?.(formData))}
        >
          Next
        </ButtonSystem>
      </div>
    </Form>
  );
};

export default SendForm;
